<template>
    <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block" id="page-display-kios">
      <div class="custom-bg-display">
        <b-container>
          <b-row>
            <b-col cols="12" sm="12" md="12" lg="">
              <div class="box-content">
                <img :src="require(`../../assets/icons/kudus.png`)" style="width:10%;">
                <h6 class="custom-title-color-white" style="font-size: 32px;margin-top: 30px;margin-bottom: 15px;font-weight: 600;">Selamat Datang di {{ nama_puskesmas }}</h6>

                <div class="box-menu" @click="pindahLamaBPJS()">
                  <img src="../../assets/bpjs-kios.png" alt="" style="height:50px;">
                  <h6 style="font-size: 24px;font-weight: 500;margin-left:30px;" class="mt-0 mb-0">Pasien Lama BPJS</h6>
                </div>

                <div  class="box-menu" @click="pindahBaru()">
                  <img src="../../assets/pasien-bpjs-umum-kios.png" alt="" style="height:50px;">
                    <h6 style="font-size: 24px;font-weight: 500;margin-left:30px;" class="mt-0 mb-0">Pasien BPJS & Umum Baru</h6>
                </div>

                <div  class="box-menu" @click="pindahLamaUmum()">
                  <img src="../../assets/pasien-umum-kios.png" alt="" style="height:50px;">
                  <h6 style="font-size: 24px;font-weight: 500;margin-left:30px;" class="mt-0 mb-0">Pasien Lama Umum</h6>
                </div>
              </div>
            </b-col>
          </b-row>

          
        </b-container>
        
        <div class="myfooter">
          <h6 style="color: #fff;font-size: 12px;text-align: center;" class="m-0">Powered by CV. Rapier Technology</h6>
        </div>
      </div>
    </div>
</template>
<script>
  import axios from "axios";
  // import { ipBackend } from "@/ipBackend";
  import moment from "moment"
  import _ from "lodash"
  moment.locale("ID")
  export default {
    components: {
    //   antrianLoket,
    },
    name: "display_kios",
    data() {
      return {
        loading: false,
        loadingTime: 0,
        maxLoadingTime: 4,
        poli: [],
        showing: false,
        msg: "",
        variant: "",
        nama_puskesmas: "",
      };
    },
    computed:{
      logo() {
        return this.$store.state.logo;
      },
    },
    watch: {
        loading(newValue, oldValue) {
          if (newValue !== oldValue) {
            this.clearLoadingTimeInterval()
  
            if (newValue) {
              this.$_loadingTimeInterval = setInterval(() => {
                this.loadingTime++
              }, 1000)
            }
          }
        },
        loadingTime(newValue, oldValue) {
          if (newValue !== oldValue) {
            
            if (newValue === this.maxLoadingTime) {
              this.loading = false
            }
          }
        }
      },
      
    mounted() {
      let id = localStorage.getItem('idp')
      this.nama_puskesmas = localStorage.getItem('nama_puskesmas')
      // this.$store.commit('set_apm_id', id)
      this.getDatas();
      // this.$store.commit('clear_data')
      this.$socket.emit("joinRoom", id);
      // this.testing()
    },
    created() {
      this.$_loadingTimeInterval = null
      this.$socket.off("refresh_admin_loket")
      this.$socket.on("refresh_admin_loket", (data) => {
        console.log(data, 'ini display kios');
        // this.print(data)
        this.getDatas()
      });
      this.$socket.off("refresh_admin_pendaftaran")
      this.$socket.on("refresh_admin_pendaftaran", (data) => {
        console.log(data, 'ini pendaftaran di kios');
        // this.print(data)

      });
      this.$socket.off("refresh_apm")
      this.$socket.on("refresh_apm", (data) => {
        console.log(data, 'ini pendaftaran di kios');
        // this.print(data)

      });
      this.$socket.off("error")
      this.$socket.on("error", (data) => {
        console.log(data, 'ini data eror');
        this.showing = true
        this.variant = "danger"
        if(data.message == 'gagal'){
          this.msg = _.toUpper(`terjadi kesalahan pada sistem.`)
        }else {
          this.msg = _.toUpper(`${data.message}. Silahkan hubungi staff yang bertugas.`)
        }      
        setTimeout(() => {
            this.showing = false;
          }, 5000);
      })
    },
    methods: {
        clearLoadingTimeInterval() {
          console.log('itikiwir');
          clearInterval(this.$_loadingTimeInterval)
          this.$_loadingTimeInterval = null
        },
        startLoading() {
          console.log('masuk sini');
          this.loading = true
          this.loadingTime = 0
          
        },
      async print(data){
        let no_antrian = `${data.initial}${data.antrian_no}`
        // let waktu = moment(new Date()).format("dddd, DD MMMM YYYY")
        // let jam = moment(new Date()).format("HH:mm:ss")
        let x = await axios.get(`http://localhost:3000/print?no_antrian=${no_antrian}&tempat=LOKET&sisa_antrian=${data.sisa_antrian}`)
        console.log(x, 'ini xx');
        this.startLoading()
      },
      tutup(){
        let win = window.open("about:blank", "_self")
        win.close()
      },
      async getDatas() {
        let vm = this;
        let poli = await vm.$axios.post("/ms_poli/list", {
          // puskesmas_id: 'a73ffa4d-79ef-4cff-8804-bfae35d391ee'
        });
      //   console.log(poli, "iki lho hasil e");
        vm.poli = poli.data.data
        },


      // routing baru
      pindahLamaUmum(){
        this.$router.push({ path: "/register_lama_umum" });
      },
      pindahLamaBPJS(){
        this.$router.push({ path: "/register_lama_bpjs" });
      },
      pindahBaru(){
        this.$router.push({ path: "/register_poli_baru" });
      },
    },
  };
  </script>
  <style scoped>
  #page-display-kios .box-content{
    width: 100%;
    height: 94vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #page-display-kios .box-menu{
    width: 60%;
    height: 80px;
    background-color: #fff;
    box-shadow: 0px 8px 8px 0px #00000040;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top:15px;
  }

  #page-display-kios .myfooter{
    width: 100%;
    height:6vh;
    background-color: #00000080;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  